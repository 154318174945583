<template>
  <UiPopup
    :model-value="modelValue"
    title="Reopen and assign lead"
    :description="
      checking
        ? ''
        : canReopen
        ? 'Select the user you want to assign this lead. It will automatically move to his pipeline.'
        : 'This lead was WON earlier and can\'t be reopened. Please create new lead to proceed work.'
    "
    :primary-button-text="checking ? '' : canReopen ? 'Reopen and assign lead' : 'Create new lead'"
    :secondary-button-text="checking ? '' : canReopen ? 'Close' : 'Ok'"
    :loading="loading"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="canReopen ? submit() : emitCreate()"
  >
    <div id="reopen-lead-popup">
      <UiLoader v-if="checking" />
      <form v-else-if="canReopen" ref="form" @submit.prevent>
        <div class="mt-2 flex flex-col gap-6">
          <div class="flex flex-col">
            <UiInputMenu
              v-model="data.user_id"
              :items="usersItems"
              name="Users"
              placeholder="Search"
              input-placeholder="Select user"
              search
              group
              avatar
              :divider="false"
              :error="useGetFieldErrors(v$, ['user_id'])"
            />
          </div>
          <div class="flex flex-col gap-2">
            <span>Write a note and it'll be saved in Lead timeline.</span>
            <UiInputTextField v-model="data.comment" name="ReasonToReopen" placeholder="Optional note to Timeline" />
            <UiInputCheckbox
              v-model="data.hide_activities"
              name="hideActivities"
              label="Hide all timeline items after Reopen"
            />
          </div>
        </div>
      </form>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { Lead, InputItem } from '@/types'
import { useUiStore } from '~/store/ui'
import { useAuthStore } from '~/store/auth'

const uiStore = useUiStore()
const authStore = useAuthStore()

const emits = defineEmits(['update:modelValue', 'input', 'create'])

type Props = {
  modelValue: boolean
  selectedLeads?: Lead[]
  lead?: Lead
  showActions?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  showActions: true,
  selectedLeads: undefined,
  lead: undefined,
})

const checking = ref(false)
const canReopen = ref(true)
const usersItems = ref<InputItem[]>([])
const selectedIds = ref<number[]>(props.lead ? [props.lead.id] : props.selectedLeads!.map((l: Lead) => l.id))

const loading = ref(false)
const data = ref({
  user_id: '',
  comment: null,
  hide_activities: false,
})

const lead = computed(() => props.lead || props.selectedLeads![0])

const { isInArchive } = useLeadStatus(lead)

onNuxtReady(async () => {
  checking.value = true
  try {
    usersItems.value = await useGetAllUsersItems(true)
    if (usersItems.value.length) {
      data.value.user_id = usersItems.value.find((u: InputItem) => u.value === authStore.getUser?.id)?.value
    }
    if (!isInArchive?.value) {
      const result = await useCheckLeadsAction(selectedIds.value[0], 'reopen')
      canReopen.value = (result as any)?.data?.status
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    checking.value = false
  }
})

const rules = computed(() => ({
  user_id: { required: helpers.withMessage('The user is required', required) },
}))

const v$ = useVuelidate(rules, data)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return
  loading.value = true
  try {
    const payload = {
      lead_ids: selectedIds.value,
      ...data.value,
    }
    await useReopenLeads(payload)
    const user = usersItems.value.find((u: InputItem) => u.value === data.value.user_id)
    const moreThanOne = selectedIds.value.length > 1
    const description = `${
      moreThanOne ? selectedIds.value.length + ' Leads were' : 'Lead was'
    } successfully reopen and assigned to ${user?.text} Pipeline.`
    if (props.showActions) {
      uiStore.showSnackBanner(
        description,
        'success',
        goToLeads,
        `Open ${moreThanOne ? selectedIds.value.length + ' leads' : 'lead'}`
      )
    } else {
      uiStore.showSnackBanner(description, 'success')
    }

    emits('input', lead.value)
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}

const goToLeads = () => {
  const params = selectedIds.value.map((l: number) => `lead_ids=${l}`).join('&')
  const link = selectedIds.value.length > 1 ? `/pipelines/sales?${params}` : `/leads/${selectedIds.value[0]}`
  navigateTo(link, {
    open: {
      target: '_blank',
    },
  })
}

const emitCreate = () => {
  emits('create')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
